<template>
  <v-container
    fluid
    style="height: 100%; margin:0px; padding:0px; min-height: inherit"
    class="d-flex flex-row"
    v-if="$vuetify.breakpoint.mdAndUp"
  >
    <chat-menu-section />
    <router-view />
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import ChatMenuSection from "./ChatMenuSection.vue";
export default {
  components: {
    ChatMenuSection
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false
    };
  },
  methods: {
    checkApproved() {
      if (
        this.profile.status === "Pending" ||
        this.profile.status === "Rejected"
      ) {
        this.$router.push({
          name: "agency-approval"
        });
      } else if (this.profile.status === "Available") {
        this.$router.push({
          name: "chat"
        });
      }
    },
    requestNotificationPermission() {
      if (Notification.permission !== "granted") {
        Notification.requestPermission().then(permission => {
          if (permission === "granted") {
            console.log("Notification permission granted.");
          } else {
            console.log("Notification permission denied.");
          }
        });
      }
    }
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      profileType: "type"
    })
  },
  watch: {
    profile: function() {
      if (this.profile) {
        this.checkApproved();
      }
    }
  },
  mounted() {
    if (this.profile) {
      this.checkApproved();
    }
    if (this.$vuetify.breakpoint.smAndDown) {
      this.$router.push({ name: "mobile-chat" });
    }
    this.requestNotificationPermission();
  }
};
</script>
<style scoped></style>
