<template>
  <div class="left-section d-flex flex-column">
    <div class="d-flex align-center">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="searchKey"
            hide-details
            placeholder="Search"
            solo
            flat
            dense
            rounded
            append-icon="mdi-magnify"
            class="my-3"
            :class="profileType == 'Agency' ? 'mr-3' : 'mr-5'"
            elevation="0"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <div style="max-height: 60vh">
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-icon class="mr-2">mdi-magnify</v-icon>Search messages
                {{ searchKey ? `for "${searchKey}"` : "" }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-for="(item, index) in filteredUsers" :key="index">
              <v-list-item-title
                v-if="item.header"
                style="font-weight: bold;"
                >{{ item.header }}</v-list-item-title
              >
              <v-list-item-title
                v-else
                @click="selectUser(item)"
                class="list-user-item"
              >
                <editable-avatar
                  :editable="false"
                  :image="item.midThumbnail ? item.midThumbnail : item.image"
                  :size="40"
                  :shadow="false"
                  class="mr-2"
                />
                {{ item.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </v-menu>
      <v-btn
        fab
        x-small
        class="mr-5"
        v-if="profileType == 'Agency'"
        @click="goToAgencyMessages"
        ><v-icon>mdi-account-group</v-icon></v-btn
      >
    </div>
    <div
      class="shop-list d-flex flex-column mr-5"
      v-if="profile && profile._id"
    >
      <v-list class="chat-list">
        <v-card
          v-for="chat in getChats"
          :key="chat._id"
          @click="selectedSingleRoom(chat)"
          class="mb-2"
          style="margin-bottom: 10px; width: 100%;"
        >
          <v-list-item class="rounded-border">
            <v-list-item-avatar>
              <editable-avatar
                :editable="false"
                :image="
                  chat.users.find(user => user.userID !== profile._id)
                    ?.thumbnail
                    ? chat.users.find(user => user.userID !== profile._id)
                        .thumbnail
                    : chat.users.find(user => user.userID !== profile._id)
                        .thumbnail
                "
                :size="40"
                :shadow="false"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ chat.users.find(user => user.userID !== profile._id)?.name }}
              </v-list-item-title>
              <v-list-item-subtitle>{{
                chat.messages[chat.messages.length - 1]
                  ? chat.messages[chat.messages.length - 1].text
                  : ""
              }}</v-list-item-subtitle>
              <p class="sub-message">
                {{
                  chat.messages[chat.messages.length - 1]?.message.length > 10
                    ? chat.messages[
                        chat.messages.length - 1
                      ]?.message.substring(0, 10) + "..."
                    : chat.messages[chat.messages.length - 1]?.message
                }}
              </p>
            </v-list-item-content>
            <v-list-item-action>
              <v-badge
                v-if="chat.unreadCount > 0"
                :content="chat.unreadCount"
                color="red"
                overlap
              ></v-badge>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-list>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import EditableAvatar from "../../components/EditableAvatar.vue";
export default {
  components: { EditableAvatar },
  data() {
    return {
      select: 0,
      filteredUsers: [],
      searchKey: ""
    };
  },
  computed: {
    ...mapGetters({
      chatRooms: "chat/getConversations",
      getChats: "chat/getChats",
      profileType: "auth/getType"
    }),
    ...mapState("auth", {
      profile: "profile"
    })
  },
  methods: {
    ...mapActions("chat", {
      getMyConversations: "getAllConversations",
      searchUsers: "searchUsers",
      getAllChats: "getAllChats"
    }),
    selectUser(item) {
      console.log("item clicked", item);
      console.log("profile", this.profile);
      //store dispatch joinRoom
      this.$store.dispatch("chat/joinRoom", {
        user1ID: this.profile._id,
        user1Type: this.profileType,
        user2ID: item._id,
        user2Type: item.userType
      });

      // if (this.$route.query._id != item._id) {
      //   this.$router.push({
      //     name: "chat-details",
      //     query: { _id: item._id, type: item.userType }
      //   });
      // }
    },
    goToAgencyMessages() {
      this.$router.push({ name: "agency-message" });
    },
    selectedSingleRoom(item) {
      console.log("item clicked at selected single room", item);
      // remove all null items in item.messages
      item.messages = item.messages.filter(message => message !== null);
      this.$store.commit("chat/setSingleRoom", item);
    }
  },
  watch: {
    searchKey(newValue) {
      if (newValue) {
        this.searchUsers(newValue)
          .then(res => {
            this.filteredUsers = res;
          })
          .catch(error => {
            if (error.response) {
              console.log(error.response.data.message);
            } else {
              console.log(error);
            }
          });
      }
    }
  },
  mounted() {
    this.loading = true;
    this.getAllChats()
      .then(() => {
        this.loading = false;
        console.log(this.getChats, "getChatsgetChatssss");

        if (!this.$route.query._id) {
          if (this.getChats.length != 0) {
            const firstChat = this.getChats[0];
            console.log("firstChat", firstChat);
            firstChat.messages = firstChat.messages.filter(
              message => message !== null
            );
            // set this first chat for singleRoom value in the store
            this.$store.commit("chat/setSingleRoom", firstChat);
            const otherUser = firstChat.users.find(
              user => user.userID !== this.profile._id
            );
            this.$router.push({
              name: "chat-details",
              query: { _id: otherUser.userID, type: otherUser.userType }
            });
          } else {
            this.$router.push({
              name: "no-messages"
            });
          }

          // setSingleRoom by finding the chat room
        }
      })
      .catch(error => {
        this.loading = false;
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
      });
    // this.getMyConversations()
    //   .then(() => {
    //     this.loading = false;
    //     if (!this.$route.query._id) {
    //       if (this.chatRooms.length != 0) {

    //         var other = "";
    //         if (
    //           this.chatRooms[0].users[0] ==
    //           this.profileType + "-" + this.profile._id
    //         ) {
    //           other = this.chatRooms[0].users[1];
    //         } else {
    //           other = this.chatRooms[0].users[0];
    //         }
    //         var otherId = other.split("-")[1];
    //         var otherType = other.split("-")[0];
    //         this.$router.push({
    //           name: "chat-details",
    //           query: { _id: otherId, type: otherType }
    //         });
    //       } else {
    //         this.$router.push({
    //           name: "no-messages"
    //         });
    //       }
    //     }
    //   })
    //   .catch(error => {
    //     this.loading = false;
    //     if (error.response) {
    //       console.log(error.response.data.message);
    //     } else {
    //       console.log(error);
    //     }
    //   });
  }
};
</script>
<style scoped>
.left-section {
  max-height: 0;
  position: sticky;
  min-height: inherit;
  top: 56px;
  max-width: 360px;
  min-width: 360px;
  width: 360px;
  padding-left: 30px;
}
.shop-list {
  flex-grow: 1;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.shop-list:hover {
  flex-grow: 1;
  overflow-y: auto;
  -ms-overflow-style: block; /* IE and Edge */
  scrollbar-width: block; /* Firefox */
}

.left-section .shop-list:hover::-webkit-scrollbar {
  display: block;
  width: 8px;
  transition-duration: 0.5s;
}
.left-section .shop-list::-webkit-scrollbar {
  display: none;
  transition-duration: 0.5s;
}
.list-user-item:hover {
  background: lightgray;
}
.list-user-item {
  padding: 10px;
  border-radius: 5px;
}
/* Track */
.left-section .needs-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.left-section .shop-list::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.left-section .shop-list::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

div :deep(.v-navigation-drawer__border) {
  display: none;
}
div :deep(.v-item--active) {
  color: #f0a000 !important;
}
div :deep(.v-item--active .v-icon) {
  color: #f0a000 !important;
}

.chat-list {
  background: #2196f314 !important;
}

.v-list-item__title {
  flex: 1 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 650;
}

.sub-message {
  color: grey;
  font-size: 15px;
}
</style>
