<template>
  <div class="d-flex flex-column" style="height: 100%">
    <div
      :class="
        selectedFile
          ? 'conversation-container-attachment'
          : 'conversation-container'
      "
      v-chat-scroll="{ always: false, smooth: false, notSmoothOnInit: true }"
      id="scroll-target"
    >
      <div v-scroll:#scroll-target="onScroll">
        <div
          v-for="message in messages"
          :key="message.index"
          class="bubble-container"
          :class="{ myMessage: message.state.author === userChatId }"
        >
          <div
            v-if="hasPrevPage && message.index == firstIndex"
            class="d-flex align-center justify-center"
          >
            <v-btn @click="loadPrevPage" text>See more</v-btn>
          </div>
          <div
            :class="{
              'd-flex': true,
              'flex-column': true,
              'first-message': message.index == 0
            }"
            :style="
              message.state.author === userChatId
                ? 'align-items: flex-start;'
                : 'align-items: flex-end;'
            "
          >
            <div
              style="margin-left: 85px; margin-top: 10px; margin-right: 30px; margin-bottom: -8px;"
              class="font-12 d-flex"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
              v-if="checkSameTime(message.index)"
            >
              <span
                class="mr-3"
                v-if="message.state.author === userChatId && user"
              >
                {{
                  user.name ? user.name : user.first_name + " " + user.last_name
                }}
              </span>
              <div>
                {{ formatDate(message.state.timestamp) }}
              </div>
            </div>
            <v-hover v-slot="{ hover }">
              <div
                style="max-width: 70%; position: relative"
                class="d-flex flex-row"
              >
                <editable-avatar
                  :image="
                    user ? (user.thumbnail ? user.thumbnail : user.image) : ''
                  "
                  :editable="false"
                  :size="40"
                  :shadow="false"
                  class="mt-3 ml-5"
                  v-if="message.author === userChatId"
                />
                <v-menu
                  offset-y
                  rounded="lg"
                  left
                  v-if="message.author !== userChatId"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div style="position: absolute; left: -28px;">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        v-show="hover"
                        class="mt-4"
                      >
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in dropmenus"
                      v-on:change="onSelectedMenu(item, message)"
                      :key="index"
                      link
                      v-show="
                        item.title != 'Edit' ||
                          (!message.media && getType(message.body) == 'text')
                      "
                    >
                      <v-list-item-title class="mr-3">
                        <v-icon v-text="item.icon" class="mr-3" />
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <div
                  :class="{
                    bubble: getType(message.body) == 'sticker' ? false : true,
                    'last-message':
                      message.index + 1 == messages.length && !typingMember
                  }"
                  v-observe-visibility="
                    (isVisible, entry) =>
                      visibilityChanged(isVisible, entry, message)
                  "
                >
                  <div
                    v-if="message.media"
                    style="max-width: 40vw; max-height: 100%; min-width: 200px; min-height: 200px; display: flex; align-items: center; justify-content: center"
                    @click="previewImage(message)"
                  >
                    <v-img
                      :src="medias[message.index]"
                      contain
                      v-if="medias[message.index]"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular indeterminate color="#fdbc3a" />
                        </v-row>
                      </template>
                    </v-img>
                    <v-progress-circular color="primary" indeterminate v-else />
                  </div>
                  <div
                    class="message text-block"
                    v-if="getType(message.body) == 'text'"
                  >
                    {{ getBody(message.body) }}
                  </div>
                  <div
                    v-else-if="getType(message.body) == 'sticker'"
                    class="mt-1 mr-5"
                  >
                    <v-img
                      :src="
                        require(`@/assets/sticker/suricata/${getBody(
                          message.body
                        )}.png`)
                      "
                      width="100"
                      height="100"
                      contain
                    />
                  </div>
                </div>
                <!-- <v-menu
               offset-y
               rounded="lg"
               right
               v-if="message.author === userChatId"
             >
               <template v-slot:activator="{ on, attrs }">
                 <div style="position: absolute; right: -28px;">
                   <v-btn
                     icon
                     v-bind="attrs"
                     v-on="on"
                     v-show="hover"
                     class="mt-4"
                   >
                     <v-icon>mdi-dots-horizontal</v-icon>
                   </v-btn>
                 </div>
               </template>
               <v-list>
                 <v-list-item
                   v-for="(item, index) in dropmenus"
                   v-on:change="onSelectedMenu(item, message)"
                   :key="index"
                   link
                   v-show="
                     item.title != 'Edit' ||
                       (!message.media && getType(message.body) == 'text')
                   "
                 >
                   <v-list-item-title class="mr-3">
                     <v-icon v-text="item.icon" class="mr-3" />
                     {{ item.title }}
                   </v-list-item-title>
                 </v-list-item>
               </v-list>
             </v-menu> -->
              </div>
            </v-hover>
          </div>
          <v-divider
            v-if="
              readline &&
                message.index == readline &&
                readline != messages[messages.length - 1].index
            "
          />
        </div>
        <div
          v-if="typingMember"
          class="d-flex flex-row"
          style="margin-bottom: 19px; margin-left: 80px; font-size: 14px; color: lightgray;"
        >
          <span>{{ `${typingMember} is typing` }}</span>
          <div class="ml-1">
            <v-img
              src="@/assets/gif/typing.gif"
              contain
              height="20"
              width="30"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="input-container mt-3">
      <v-btn icon @click="onSelectImage" class="ml-2 mt-1">
        <v-icon v-text="'mdi-image-area'" size="20" color="white" />
      </v-btn>
      <div class="mr-2 mt-1">
        <chat-sticker :onSend="onSendSticker" />
      </div>
      <div style="width: 100%;" class="message-section">
        <div class="d-flex flex-row" v-if="!selectedFile">
          <v-textarea
            @keydown.enter.prevent="sendMessage"
            @keydown="typingMessage"
            v-model="messageText"
            placeholder="Enter your message"
            flat
            solo
            hide-details
            ref="message"
            rows="1"
            auto-grow
            class="message-text-input"
          />
          <v-menu
            bottom
            offset-y
            origin="bottom bottom"
            :close-on-content-click="false"
            v-model="emojiKeyboard"
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="{ ...menu }"
                v-if="$vuetify.breakpoint.xs"
                large
                class="ml-2"
              >
                <v-icon
                  v-text="'mdi-emoticon-happy-outline'"
                  size="20"
                  class="mt-1"
                  color="#0A5B8A"
                />
              </v-btn>
              <v-tooltip top v-else>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    large
                    class="ml-2"
                  >
                    <v-icon
                      v-text="'mdi-emoticon-happy-outline'"
                      size="20"
                      class="mt-1"
                      color="#0A5B8A"
                    />
                  </v-btn>
                </template>
                <span>Open Emoji Keyboard</span>
              </v-tooltip>
            </template>
            <v-card>
              <VEmojiPicker @select="selectEmoji" />
            </v-card>
          </v-menu>
        </div>
        <div
          style="width: 100%; background-color:#f9fafb; border-radius:26px"
          v-else
        >
          <div style="position:relative; width: 80px; border-radius: 20px">
            <v-img
              :src="selectedImage"
              alt="image attachment"
              height="50px"
              width="50px"
              class="ma-3 rounded-lg"
              cover
            />
            <v-btn
              @click="onRemoveImage"
              class="remove_button"
              fab
              x-small
              color="primary"
            >
              <v-icon v-text="'mdi-close'" size="16" />
            </v-btn>
          </div>
        </div>
      </div>
      <v-btn @click="sendMessage" icon large class="ml-2" :loading="sending">
        <v-icon size="20" class="mt-1" color="white">mdi-send</v-icon></v-btn
      >
      <input
        ref="uploader"
        class="d-none"
        type="file"
        accept="image/*"
        @change="onFileChanged"
      />
      <confirm-delete-dialog
        :deleteDialog="deleteDialog"
        :onClose="onCloseDeleteDialog"
        :onConfirm="onConfirmDelete"
      />
    </div>
  </div>
</template>
<script>
import dateFormat from "dateformat";
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
import ConfirmDeleteDialog from "../../components/common/ConfirmDeleteDialog.vue";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import Vue from "vue";
import ChatSticker from "./ChatSticker.vue";
Vue.use(Viewer, {
  debug: true,
  defaultOptions: {
    zIndex: 9999
  }
});

export default {
  props: {
    activeConversation: Object,
    user: Object,
    userChatId: String,
    actcvMessages: Object,
    chatId: String,
    activeChatMessages: Array
  },
  components: {
    EditableAvatar,
    ConfirmDeleteDialog,
    ChatSticker
  },
  data() {
    return {
      messages: [],
      messageText: "",
      isSignedInUser: false,
      typingMember: "",
      hasPrevPage: false,
      firstIndex: 0,
      emojiKeyboard: false,
      selectedFile: null,
      selectedImage: null,
      medias: {},
      dropmenus: [
        { title: "Delete", icon: "mdi-delete" },
        { title: "Edit", icon: "mdi-pencil" }
      ],
      deleteDialog: false,
      selected: null,
      sending: false,
      editing: false,
      readline: 0,
      offset: 0
    };
  },
  methods: {
    ...mapActions({
      initConversationsClient: "chat/initConversationsClient",
      updateConversation: "chat/updateConversation",
      sendMessageNotification: "chat/sendMessageNotification"
    }),
    sendMessage(e) {
      var obj = { type: "text", body: this.messageText };
      if (!this.editing) {
        console.log(this.chatId, "32423432");

        if (this.messageText && !this.selectedFile) {
          if (e.ctrlKey || e.altKey || e.shiftKey) {
            this.messageText += "\n";
            this.$refs.message.$refs.input.setSelectionRange(
              this.messageText.length,
              this.messageText.length
            );
            setTimeout(() => {
              this.$refs.message.$refs.input.scrollTop = this.$refs.message.$refs.input.scrollHeight;
            }, 50);
            return;
          }
          this.sending = true;

          this.activeConversation
            .sendMessage(JSON.stringify(obj))
            .then(() => {
              this.sendMessageNotification({
                room: this.chatId,
                message: this.messageText
              }).catch(res => {
                console.log(res.response.data.message);
              });
              this.onRefreshChat();
            })
            .catch(error => {
              console.log(error);
              this.$router.go();
            });
        } else if (this.selectedFile) {
          this.sending = true;
          var formData = new FormData();
          formData.append("file", this.selectedFile);
          this.activeConversation.sendMessage(formData).then(() => {
            this.onRefreshChat();
          });
        }
      } else if (this.messageText) {
        this.sending = true;
        this.selected.updateBody(JSON.stringify(obj)).then(() => {
          this.messageText = "";
          this.sending = false;
        });
      }
    },
    onRefreshChat() {
      this.messageText = "";
      this.selectedFile = null;
      this.selectedImage = null;
      this.sending = false;
      this.activeConversation.setAllMessagesRead();
      this.updateConversation({ _id: this.chatId }).catch(error => {
        console.log(error);
      });

      var container = this.$el.querySelector("#scroll-target");
      container.scrollTop = container.scrollHeight;
    },
    onSendSticker(sticker) {
      var msg = { type: "sticker", body: sticker };
      this.sending = true;
      this.activeConversation
        .sendMessage(JSON.stringify(msg))
        .then(() => {
          this.onRefreshChat();
        })
        .catch(error => {
          console.log(error.message);
          this.$router.go();
        });
    },
    getType(item) {
      try {
        var obj = JSON.parse(item);
        return obj.type;
      } catch (error) {
        console.log(error.message);
      }
      return "text";
    },
    getBody(item) {
      try {
        var obj = JSON.parse(item);
        return obj.body;
      } catch (error) {
        console.log(error.message);
      }
      return item;
    },
    loadPrevPage() {
      this.activeConversation
        .getMessages(30, this.messages[0].index - 1, "backwards")
        .then(newMessages => {
          this.hasPrevPage = newMessages.hasPrevPage;
          if (newMessages.items.length)
            this.firstIndex = newMessages.items[0].state.index;
          else this.firstIndex = -1;
          this.messages = [...newMessages.items, ...this.messages];
          newMessages.items.map(async m => {
            if (m.media) {
              this.medias[m.index] = await m.media.getContentTemporaryUrl();
              this.medias = { ...this.medias };
            }
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    typingMessage(key) {
      if (key.keyCode != 13 && key.keyCode != 27) {
        try {
          this.activeConversation.typing();
        } catch (error) {
          console.log(error);
        }
      } else if (key.keyCode == 27 && this.editing == true) {
        this.editing = false;
        this.messageText = "";
      }
      if (this.activeConversation.connectionState != "connected") {
        //this.initConversationsClient();
      }
    },
    formatDate(date) {
      var today = new Date();
      var todayFormat = "hh:MM TT";
      var weekFormat = "ddd hh:MM TT";
      var dayFormat = "mm/dd hh:MM TT";
      var diffTime = today.getTime() - date.getTime();
      var diffDay = diffTime / (1000 * 3600 * 24);
      if (diffDay < 1) {
        return dateFormat(this.convertTZ(date, this.timezone), todayFormat);
      } else if (diffDay < 7) {
        return dateFormat(this.convertTZ(date, this.timezone), weekFormat);
      } else {
        return dateFormat(this.convertTZ(date, this.timezone), dayFormat);
      }
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York"
          }
        )
      );
    },
    checkSameTime(index) {
      if (index == 0) return true;
      if (!this.messages[index]) return true;
      if (!this.messages[index - 1]) return false;
      if (!this.messages[index].state) return true;
      if (
        this.messages[index].state.author !=
        this.messages[index - 1].state.author
      ) {
        return true;
      }
      // if (this.messages.length > index + 2) {
      //   if (
      //     this.messages[index].state.author !=
      //     this.messages[index + 1].state.author
      //   )
      //     return true;
      // }
      const date = new Date(this.messages[index].state.timestamp);
      const pDate = new Date(this.messages[index - 1].state.timestamp);
      if (date.getTime() - pDate.getTime() < 1000 * 60 * 2) return false;
      return true;
    },
    selectEmoji(emoji) {
      this.insertText(emoji.data);
      this.makeFocus();
    },
    insertText(text) {
      const el = this.$refs.message.$refs.input;
      let cursorPos = el.selectionEnd;
      this.messageText =
        this.messageText.substring(0, cursorPos) +
        text +
        this.messageText.substring(cursorPos);
      cursorPos += text.length;
      this.$nextTick(() => el.setSelectionRange(cursorPos, cursorPos));
      this.emojiKeyboard = false;
    },
    makeFocus() {
      this.$refs.message.$refs.input.focus();
    },
    onFileChanged(e) {
      if (e.target.files.length != 0) {
        this.selectedFile = e.target.files[0];
        this.selectedImage = URL.createObjectURL(this.selectedFile);
        this.messageText = "";
        e.target.value = null;
      }
    },
    getImageUrl(imageFile) {
      return URL.createObjectURL(imageFile);
    },
    onSelectImage() {
      this.isSelectingPhoto = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingPhoto = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onRemoveImage() {
      this.selectedFile = null;
      this.selectedImage = null;
    },
    onSelectedMenu(item, message) {
      console.log(message, message.attributes);
      this.selected = message;
      if (item.title == "Delete") {
        this.deleteDialog = true;
      } else if (item.title == "Edit" && !message.media) {
        this.editing = true;
        this.messageText = this.getBody(this.selected.body);
      }
    },
    onCloseDeleteDialog() {
      this.deleteDialog = false;
    },
    onConfirmDelete() {
      this.deleteDialog = false;
      this.selected.remove().catch(error => {
        console.log(error);
      });
    },
    visibilityChanged(isVisible, entry, message) {
      if (isVisible && entry.intersectionRatio > 0.5) {
        if (this.activeConversation.lastReadMessageIndex < message.index) {
          this.activeConversation.updateLastReadMessageIndex(message.index);
        }
      }
    },
    previewImage(message) {
      message.media
        .getContentTemporaryUrl()
        .then(res => {
          var array = Object.values(this.medias);
          var indexArr = Object.keys(this.medias);
          if (this.medias[message.index]) {
            this.$viewerApi({
              images: array,
              options: {
                initialViewIndex: indexArr.indexOf(`${message.index}`)
              }
            });
          } else {
            array.push(res);
            this.$viewerApi({
              images: array,
              options: {
                initialViewIndex: array.length - 1
              }
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    onScroll(e) {
      this.offset =
        e.target.scrollTop + e.target.clientHeight - e.target.scrollHeight;
    }
    //     formatDate(dateString) {
    //   const date = new Date(dateString);
    //   return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    // },
    // checkSameTime(index) {
    //   if (index === 0) return true;
    //   return new Date(this.messages[index].date).getMinutes() !== new Date(this.messages[index - 1].date).getMinutes();
    // }
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      timezone: "usersetting/getTimeZone",
      singleRoom: "chat/getSingleRoom"
    })
  },
  mounted() {
    console.log("Active Chat Messages:", this.activeChatMessages); // Log activeChatMessages
    console.log("Received messages in Conversation.vue:", this.actcvMessages);
    // console log singleRoom
    console.log("Single Room:", this.singleRoom.value);

    this.activeConversation.getMessages().then(newMessages => {
      this.hasPrevPage = newMessages.hasPrevPage;
      if (newMessages.items.length)
        this.firstIndex = newMessages.items[0].state.index;
      else this.firstIndex = -1;
      this.messages = [...this.messages, ...newMessages.items];
      newMessages.items.map(async m => {
        if (m.media) {
          this.medias[m.index] = await m.media.getContentTemporaryUrl();
          this.medias = { ...this.medias };
        }
      });
    });
    this.activeConversation
      .getParticipantByIdentity(this.userChatId)
      .then(participant => {
        this.readline = participant.lastReadMessageIndex;
      })
      .catch(() => {
        console.log("read line error");
      });
    this.activeConversation.on("messageAdded", async message => {
      this.messages = [...this.messages, message];
      if (message.media) {
        this.medias[
          message.index
        ] = await message.media.getContentTemporaryUrl();
        this.medias = { ...this.medias };
      }
      if (this.offset > -200) {
        setTimeout(() => {
          var container = this.$el.querySelector("#scroll-target");
          container.scrollTop = container.scrollHeight;
        }, 100);
      }
    });
    this.activeConversation.on("messageRemoved", async message => {
      this.messages = this.messages.filter(m => m.index != message.index);
    });
    this.activeConversation.on("participantUpdated", state => {
      if (state.participant.identity == this.userChatId) {
        this.readline = state.participant.lastReadMessageIndex;
      }
    });
    this.activeConversation.on("typingStarted", () => {
      this.typingMember = this.user.name
        ? this.user.name
        : this.user.first_name;

      if (this.offset > -100) {
        setTimeout(() => {
          var container = this.$el.querySelector("#scroll-target");
          container.scrollTop = container.scrollHeight;
        }, 100);
      }
    });
    this.activeConversation.on("typingEnded", () => {
      this.typingMember = "";
    });
  },
  watch: {
    messages: {
      handler(newMessages) {
        console.log("Updated Messages in Conversation.vue:", newMessages);
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
<style scoped>
.conversation-container {
  margin: 0 auto;
  width: 100%;
  flex-basis: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  overflow-wrap: anywhere;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #6c9db9;
  border-radius: 5px;
}
.conversation-container-attachment {
  margin: 0 auto;
  width: 100%;
  height: calc(100vh - 260px);
  border: 3px solid #f1f1f1;
  overflow-y: auto;
  overflow-x: hidden;
  overflow-wrap: anywhere;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.bubble-container {
  text-align: left;
}
.bubble {
  border: 2px solid #f1f1f1;
  background-color: #fdfbfa;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 20px;
  float: right;
}
.myMessage .bubble {
  background-color: #abf1ea;
  border: 2px solid #87e0d7;
  float: left;
}
.first-message {
  margin-top: 20px;
}
.last-message {
  margin-bottom: 50px;
}
.input-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
.message-section {
  border: solid;
  border-radius: 25px;
  border-width: 1px;
  border-color: #c1c1c1;
  background: white;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.remove_button {
  position: absolute;
  top: 0px;
  right: 0px;
}
.message-text-input {
  width: 100%;
  border-radius: 25px;
}
.message-text-input :deep(textarea) {
  max-height: 7rem;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
