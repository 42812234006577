<template>
  <v-card
    :class="{
      'chat-card': true,
      'current-chat':
        otherId == $route.query._id && otherType == $route.query.type
    }"
    @click="goToMessages"
  >
    <v-card-text class="d-flex flex-row align-center">
      <div>
        <editable-avatar
          :image="user ? (user.thumbnail ? user.thumbnail : user.image) : ''"
          :editable="false"
          :size="50"
        />
      </div>
      <div class="ml-4" style="flex-grow: 1; min-width: 50px">
        <div class="d-flex flex-row" style="justify-content: space-between">
          <span class="username" v-if="user">
            {{ user.name ? user.name : user.first_name + " " + user.last_name }}
          </span>
          <span v-if="lastMessage" style="white-space: nowrap;">
            {{ formatDate(lastMessage.date) }}
          </span>
        </div>
        <div
          v-if="lastMessage"
          class="mt-1 d-flex flex-row"
          style="justify-content: space-between;"
        >
          <div
            style="text-overflow: ellipsis; overflow: hidden;white-space: nowrap;"
          >
            {{
              lastMessage.media
                ? "Image"
                : messageType == "sticker"
                ? "Sticker"
                : lastMessage.message
            }}
          </div>
          <div
            v-if="unreadCount"
            style="width: 22px; height: 22px; min-width: 21px; border-radius: 11px; background: blue; text-align: center; color: white; font-weight: bold;"
          >
            {{ unreadCount > 10 ? "•" : unreadCount }}
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import dateFormat from "dateformat";
import EditableAvatar from "@/components/EditableAvatar.vue";

export default {
  props: {
    conversationId: String,
    users: Array,
    lastMessage: Object,
    unreadCount: Number
  },
  components: {
    EditableAvatar
  },
  data() {
    return {
      other: "",
      otherId: "",
      otherType: "",
      user: null,
      activeConversation: null,
      messageType: "text"
    };
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      profileType: "auth/getType",
      conversationsClient: "chat/conversationsClient",
      timezone: "usersetting/getTimeZone"
    })
  },
  watch: {
    users(newValue) {
      if (newValue) {
        this.getUserInfo();
      }
    },
    // conversationsClient(newValue) {
    //   if (newValue) {
    //     this.getActiveConversation();
    //   }
    // }
  },
  methods: {
    ...mapActions({
      getAgency: "agency/getAgency",
      getVolunteer: "volunteer/getVolunteer",
      getAdmin: "auth/getAdmin",
      initConversationsClient: "chat/initConversationsClient"
    }),
    getUserInfo() {
      if (this.users[0].userID == this.profile._id) {
        this.other = this.users[1];
      } else {
        this.other = this.users[0];
      }
      this.otherId = this.other.userID;
      this.otherType = this.other.userType;
      this.user = this.other;
    },
    goToMessages() {
      if (
        this.otherId &&
        this.otherType &&
        this.$route.query._id != this.otherId
      ) {
        if (this.$vuetify.breakpoint.smAndDown) {
          this.$router.push({
            name: "mobile-chat-room",
            query: { _id: this.otherId, type: this.otherType }
          });
        } else {
          this.$router.push({
            name: "chat-details",
            query: { _id: this.otherId, type: this.otherType }
          });
        }
      }
    },
    formatDate(date) {
      var today = new Date();
      var todayFormat = "hh:MM TT";
      var weekFormat = "ddd hh:MM TT";
      var dayFormat = "mm/dd/yyyy";
      var diffTime = today.getTime() - new Date(date).getTime();
      var diffDay = diffTime / (1000 * 3600 * 24);
      if (diffDay < 1) {
        return dateFormat(date, todayFormat);
      } else if (diffDay < 7) {
        return dateFormat(date, weekFormat);
      } else {
        return dateFormat(date, dayFormat);
      }
    }
  },
  mounted() {
    this.getUserInfo();
  }
};
</script>
<style scoped>
.chat-card {
  height: 80px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.current-chat {
  background: #eef3f8;
}
.username {
  color: #242f36;
  font-size: 14px;
  font-family: "Poppins-Regular";
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.current-chat :deep(.username) {
  font-weight: bold;
}
</style>
